import React, { FunctionComponent, useState, useEffect } from "react";
import CardRenderer from "../CardRenderer";
import Section from "../Section";
import { CardType } from "../CardRenderer/models";
import Button from "../Button";
import "./styles.scss";
import RichText from "../RichText";

interface SimpleListingInterface {
  _id: string;
  bgColor: {
    title: string;
  };
  description: string;
  _rawBlockDescription?: any;
  heading: string;
  listingType: string;
  _rawPersonSlides?: CardType[];
  _rawSlides?: CardType[];
  isHomePageVersion?: boolean;
  columnNumber?: string;
  isHalfSizedDescription?: boolean;
  isImagesMonochrome?: boolean;
  subHeading?: string;
}

const SimpleListing: FunctionComponent<SimpleListingInterface> = data => {
  const {
    bgColor,
    description,
    heading,
    listingType,
    _rawPersonSlides,
    _rawSlides,
    isHomePageVersion,
    columnNumber,
    isHalfSizedDescription,
    isImagesMonochrome,
    subHeading,
    _rawBlockDescription
  } = data;
  const isPersonListing = listingType === "personListing";
  const isPersonBusinessListing = listingType === "personBusinessListing";
  const [listing, setListing] = useState(
    isPersonListing ? _rawPersonSlides?.slice(0, 8) : isPersonBusinessListing ? _rawPersonSlides : _rawSlides
  );
  const listingClass = `${
    listingType === "genericListing"
      ? columnNumber == "three-column"
        ? "grid-col-12 grid-col-md-6 grid-col-xl-4"
        : "grid-col-12 grid-col-md-6 grid-col-xl-3"
      : listingType === "pageListing"
      ? "grid-col-12 grid-col-md-4"
      : listingType === "personBusinessListing"
      ? "grid-col-12 grid-col-md-6 grid-col-xl-3"
      : "grid-col-6 grid-col-md-6 grid-col-xl-3"
  }`;
  const handleLoadMore = () => {
    const slidesSize = (listing?.length || 0) + 8;
    setListing(_rawPersonSlides?.slice(0, slidesSize));
  };

  return (
    <Section
      title={heading}
      subHeading={subHeading}
      theme={bgColor.title}
      description={description}
      sectionClassName={`c-simple-listing ${data._id} ${isHomePageVersion ? "home-page-version" : ""} ${
        isHalfSizedDescription ? "half-sized-description" : ""
      } ${isImagesMonochrome ? "monochrome-images" : ""} ${_rawBlockDescription ? "block-description-bg-color" : ""}`}
    >
      <div
        className={`grid-row grid-row-center ${listingType === "genericListing" && columnNumber ? columnNumber : ""}`}
      >
        {_rawBlockDescription && (
          <h3 className="c-simple-listing-blockDescription">
            <RichText data={_rawBlockDescription} />
          </h3>
        )}
        {listing?.length &&
          listing.map((card: CardType, index: number) => (
            <div className={`${listingClass} c-simple-listing-card`} key={index}>
              <CardRenderer card={card} listingType={listingType} />
            </div>
          ))}
        {isPersonListing && listing?.length !== _rawPersonSlides?.length && (
          <Button variant="tertiary" size="lg" onClick={handleLoadMore}>
            Load More
          </Button>
        )}
      </div>
    </Section>
  );
};

export default SimpleListing;
