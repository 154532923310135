import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { Eligibility } from "../../../images/icons/eligibility";
import { ImageInterface, VideoInterface } from "../../../types/SanityTypes";
import CtaBlock from "../../CtaBlock";
import { CardRendererInterface } from "../models";
import VideoBlock from "../../VideoBlock";
import RichText from "../../RichText";
import { Link } from "../../Link";
import "./styles.scss";
import { IMAGE_SIZES } from "../../../constants";

const GenericCard: FunctionComponent<CardRendererInterface> = ({ card, listingType }) => {
  const animatedElement = useRef<HTMLDivElement>(null);
  const [isCardVisible, setIsCardVisible] = useState(false);

  const checkVisibility = () => {
    if (animatedElement.current) {
      const positionFromBottom = animatedElement.current.getBoundingClientRect().bottom;
      const positionFromTop = animatedElement.current.getBoundingClientRect().top;
      if ((positionFromBottom + positionFromTop) * 0.67 - window.innerHeight <= 0) {
        setIsCardVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);
    window.addEventListener("resize", checkVisibility);
    return () => {
      window.removeEventListener("scroll", checkVisibility);
      window.removeEventListener("resize", checkVisibility);
    };
  }, []);

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (card.linkToSection) {
      e.preventDefault();
      const targetElement = document.getElementsByClassName(`${card.linkToSection._id}`);
      targetElement[0]?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const GenericCardImage = ({
    image,
    shortVideo,
    listingType
  }: {
    image: ImageInterface;
    shortVideo: VideoInterface;
    listingType: string;
  }) => {
    const [imageWidth, imageHeight] =
      listingType === "partners" ? [620, 348] : listingType === "hairTypes" ? [305, 380] : [450, 420];
    return (
      <div
        className={`c-genericCard__image smb-xs-24 ${listingType === "page" ? "smb-md-36" : "smb-md-24"} `}
        aria-hidden={true}
      >
        {listingType === "partners" ? (
          <SanityImage
            {...image}
            sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
            width={imageWidth}
            htmlWidth={imageWidth}
            htmlHeight={imageHeight}
            height={imageHeight}
            className={`img-fluid`}
          />
        ) : (
          <SanityImage
            {...image}
            sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
            width={imageWidth}
            height={imageHeight}
            htmlWidth={imageWidth}
            htmlHeight={imageHeight}
            className={`img-fluid  ${listingType === "generic" && isCardVisible ? "un-zoomed" : ""}`}
          />
        )}
        {listingType === "hairTypes" && shortVideo && (
          <VideoBlock
            classnames="hover-video"
            webm={shortVideo.webm}
            fallback={shortVideo.fallback}
            alt={shortVideo.alt}
            autoPlay
            muted
            loop
          />
        )}
      </div>
    );
  };

  return (
    <div className={`c-genericCard ${listingType}`} ref={animatedElement}>
      {listingType == "partners" ? (
        <CtaBlock ctaBlock={card.ctaBlock}>
          {card.image && <GenericCardImage image={card.image} listingType={listingType} shortVideo={card.shortVideo} />}
          {card.title && <h3 className={`c-genericCard__title smb-xs-24 smb-md-36`}>{card.title}</h3>}
          {card.description && <p className="c-genericCard__description smb-md-36 smb-xs-24">{card.description}</p>}
        </CtaBlock>
      ) : (
        <div className="c-genericCard__wrapper">
          {card.image && <GenericCardImage image={card.image} listingType={listingType} shortVideo={card.shortVideo} />}
          {card.title && card.isTitleLink ? (
            <h3 className={`c-genericCard__title smb-xs-24 smb-md-36`}>
              <Link
                className="c-genericCard__title-link"
                to={card.isTitleExternalLink ? card.titleExternalLink : card.titleCtaLink?.slug?.current || "/"}
                isExternalLink={card.isTitleExternalLink}
              >
                {card.title}
              </Link>
            </h3>
          ) : (
            <h3 className={`c-genericCard__title smb-xs-24 smb-md-36`}>{card.title}</h3>
          )}
          {card.label && (
            <p className={`c-genericCard__label smb-xs-24 ${listingType === "page" ? "smb-md-36" : "smb-md-24"}`}>
              {card.label}
            </p>
          )}
          {card.eligibility && (
            <p className="c-genericCard__eligibility smb-md-36 smb-xs-24">
              <Eligibility className="c-genericCard__eligibility-icon" />
              {card.eligibility}
            </p>
          )}
          {listingType == "page" && card.pageDescription && (
            <div className="c-genericCard__description smb-md-36 smb-xs-24">
              <RichText data={card.pageDescription} />
            </div>
          )}
          {listingType !== "page" && card.description && (
            <p className="c-genericCard__description smb-md-36 smb-xs-24">{card.description}</p>
          )}
          {card.ctaBlock && card.ctaBlock?.ctaLabel && listingType !== "partners" && (
            <p className={`${listingType === "page" ? "c-genericCard__link--button" : "c-genericCard__link "}`}>
              <CtaBlock ctaBlock={card.ctaBlock} ctaLabel={card.ctaBlock?.ctaLabel} onClickFunction={handleLinkClick} />
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default GenericCard;
