import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { ImageInterface, VideoInterface } from "../../../types/SanityTypes";
import { Link } from "../../Link";
import { CardRendererInterface } from "../models";
import VideoBlock from "../../VideoBlock";
import "./styles.scss";
import { IMAGE_SIZES } from "../../../constants";

const CollectionCard: FunctionComponent<CardRendererInterface> = ({ card }) => {
  const CollectionCardImage = ({ image, shortVideo }: { image: ImageInterface; shortVideo: VideoInterface }) => {
    const [imageWidth, imageHeight] = [311, 305];
    return (
      <div className="c-collectionCard__image smb-xs-8 smb-md-16" aria-hidden={true}>
        <SanityImage
          {...image}
          sizes={IMAGE_SIZES.SMALL_SQUARE}
          width={imageWidth}
          height={imageHeight}
          htmlWidth={imageWidth}
          htmlHeight={imageHeight}
          className={`img-fluid`}
        />
        {shortVideo && (
          <VideoBlock
            classnames="hover-video"
            webm={shortVideo.webm}
            fallback={shortVideo.fallback}
            alt={shortVideo.alt}
            autoPlay
            muted
            loop
          />
        )}
      </div>
    );
  };
  return (
    <div className={`c-collectionCard`}>
      <Link className="c-collectionCard__link" to={`collection/${card.collection?.slug?.current}`}>
        <CollectionCardImage image={card.image} shortVideo={card.shortVideo} />
        {card.label && <p className="c-collectionCard__label smb-xs-0 smb-md-16">{card.label}</p>}
        <h3 className="c-collectionCard__title smb-xs-24 smb-md-36">{card.collection.name}</h3>
      </Link>
    </div>
  );
};

export default CollectionCard;
