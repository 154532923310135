import React, { FunctionComponent } from "react";
import GenericCard from "./GenericCard";
import { CardType } from "./models";
import PersonCard from "./PersonCard";
import CollectionCard from "./CollectionCard";

type CardRenderableSanityTypeNameToComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [typeName: string]: FunctionComponent<any>;
};

const cardsMap: CardRenderableSanityTypeNameToComponentMap = {
  simplePersonSlide: PersonCard,
  simpleSlide: GenericCard,
  collectionSlide: CollectionCard
};

interface CardRendererInterface {
  card: CardType;
  listingType: string;
}

const CardRenderer: FunctionComponent<CardRendererInterface> = ({ card, listingType }) => {
  if (!card) {
    return null;
  }
  const getComponent = (cardType: string) => {
    const component = cardsMap[cardType];
    if (component) {
      return component;
    } else {
      console.error(`Unknown card type: ${cardType}`);
      return null;
    }
  };

  const Comp = getComponent(card._type);

  return Comp
    ? React.createElement(Comp, {
        card,
        listingType: listingType.replace("Listing", "")
      })
    : null;
};

export default CardRenderer;
